/*
 * 업무구분: 안내자료
 * 화 면 명: MSPSA203P
 * 화면설명: 안내자료_고객선택 수신(SMS)
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.05.26
 * 작 성 자: 이태훈
 */
<template>
  <ur-page-container :show-title="true" :title="isMSPCM212M? '고객검색': '문자발송'" type="subpage" @on-header-left-click="fn_HistoryBack" @on-scroll-bottom="fn_LoadingData()">
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="layout-both-24">
      <div class="ns-search-2box">
        <div class="first">
          <div class="ns-rec-box">
            <msp-bottom-select class="ns-dropdown-sheet ns-dropdown-pos ns-confirm-sheet" underline
              :items="lv_selectGrpInfo.items" v-model="lv_selectGrpInfo.selectdVal.value" @input="fn_onSearchCustList()" />
          </div>
        </div>
        <div class="end">
          <mo-text-field v-model="lv_searchKeyword" searchable placeholder="고객명" @keyup.enter="fn_onSearchBtnClicked" @click-icon="fn_onSearchBtnClicked" underline class="input-sch" />
        </div>
      </div>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-check-sum">
        <mo-list-item >
          <mo-checkbox v-model="lv_isCustListSelectAll" :disabled="lv_custListItems.length === 0" @input="fn_isCustListSelectAllItems(lv_isCustListSelectAll)"></mo-checkbox>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">{{checkCount > 0 ? checkCount + '명 선택' : '총 ' + lv_custListTotalCnt+ '명' }}</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>
    <ur-box-container direction="column" alignV="start" class="ns-list-area">
      <ur-box-container v-if="lv_custListItems.length === 0 && isAfterFirstSearch === true" alignV="start" componentid="ur_box_container_004" direction="column" class="no-data"><!--ns-contract-list -->
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info" v-if="lv_custListSearchStatus==='1'"><span>데이터가 없습니다.</span></div>
            <div class="list-item__contents__info" v-if="lv_custListSearchStatus==='2'"><span>검색결과가 존재하지 않습니다.</span></div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- Data -->
      <ur-box-container alignV="start" componentid="ur_box_container_04" direction="column" class="ns-check-list">
        <mo-list :list-data="lv_custListItems">
          <template #list-item="{ item, index }">
            <!-- TODO 체크되었을때 클래스 변경 -->
            <!-- <mo-list-item :class="item.checked ? 'on' : ''"> -->
            <mo-list-item :class="lv_custListItems[index].chkClass ? 'on' : ''">
              <mo-checkbox v-model="lv_selectedCustItems" :value="item" @input="fn_checkBoxIsPossible(lv_selectedCustItems.length, item, index)"/>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip">{{item.custNm}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span>{{$commonUtil.dateDashFormat(item.dob)}}</span><em>|</em><span>{{item.custSxdsNm}}</span><em>|</em><span>{{item.celno}}</span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_05" direction="column" class="ns-btn-relative on">
        <div class="relative-div">
          <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click.stop="fn_onCancelBtnClicked">취소</mo-button>
          <mo-button v-if="isMSPCM212M" componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="this.lv_selectedCustItems.length === 0" @click.stop="fn_onNextBtnClicked">추가</mo-button>
          <mo-button v-else componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="this.lv_selectedCustItems.length === 0" @click.stop="fn_onNextBtnClicked">다음</mo-button>
        </div>
      </ur-box-container>
      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
      <div class="ns-height30"></div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'; /* dropdown bottom-sheet */
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA203P",
    screenId: "MSPSA203P",
    props: {
      // 부모창(TSSSA001M)에서 현 팝업창의 노출 여부를 전달하는 파라미터
      'pIsShowTSSSP203P': Boolean,
      'pIsSearch': {
        type: Boolean,
        default () { return false }
      },
      paramData: []

    },
    components: {
      MspBottomSelect
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isAfterFirstSearch: false,
        // title: '안내자료_고객선택 수신(SMS)', // 화면명
        isMngr: false, // 지점장 여부
        authCd: 'U000', // 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
        isScrolled: false,
        lv_userInfo: {}, // 사용자정보
        lv_isShowTSSSP203P: false, // 부모창에서 전달받은 노출여부 파라미터(pr_IsShowTSSSA012P)를 저장
        lv_isCustListSelectAll: false, // 고객목록 전체 선택 Checkbox를 체크했는지 여부
        lv_selectedCustItems: [], // 선택된 고객 목록 저장
        lv_searchKeyword: '', // 검색 키워드(고객명)
        lv_selectGrpInfo: { // 고객그룹목록
          selectdVal: {
            value: '',
            text: '전체고객'
          },
          items: [
            {
              value: '',
              text: '전체고객'
            },
            {
              value: '01',
              text: '가망고객'
            },
            {
              value: '02',
              text: '임시고객'
            },
            {
              value: '03',
              text: '본인모집고객'
            },
            {
              value: '04',
              text: '타인모집고객'
            },
            {
              value: '05',
              text: '관심고객'
            }
          ],
        },
        stndKeyList: [], // NextKey 방식의 페이징에서 다음 페이지의 첫번째 항목의 Key값 저장(http.js에 선언된 변수명과 동일한 이름 사용)
        pageRowCnt: 20, // 한 페이지에 노출되는 데이터 건수(http.js에 선언된 변수명과 동일한 이름 사용)
        //lv_gropInfoPageRowCnt: 100, // 고객그룹목록 한페이지에 노출되는 데이터 건수
        lv_custListPageRowCnt: 20, // 고객목록 한 페이지에 노출되는 데이터 건수

        // 고객목록 fdp-infinite가 데이터를 로딩중인 상태인지의 여부플 판단
        // true : 로딩중인 상태로 스크롤바가 목록 하단에 도달하여도 데이터를 더 호출하지 않음
        // false : 로딩이 끝난 상태로 인식하여 스크롤바가 목록 하단에 도달하면 데이터를 더 호출할 수 있음
        lv_isCustListLoadingStatus: false,
        lv_custListItems: [], // 고객목록(실제 fdp-infinite에 바인딩되는 데이터)
        lv_sACntntCustVOList: [], // 서비스 조회 데이터 객체(SVO) 처리 전용
        lv_custListTotalCnt: 0, // 페이징 처리 전용
        lv_custListSearchStatus: '1', // UI 처리 전용
        lv_custListMoreData: true, // 더 이상 조회할 데이터가 있는지 여부
        isMSPCM212M: false, // 내그룹 화면으로부터의 진입 여부
      };
    },


    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
     
    /** computed 함수 정의 영역 */
    computed: {
      checkCount () {
      let lv = this
      return lv.lv_selectedCustItems.length
      }
    },
    /** watch 정의 영역 */
    watch: {
      // isShowTSSSA002P 프러퍼티 값의 변화가 발생하면 호출
      // 부모 화면에서 팝업을 실행하기 위해 isShowTSSSA002P 값에 true를 할당하면 호출
      // 팝업 창 종료(X버튼)시 발생하는 'closeTSSSA002P 이벤트에 의해 isShowTSSSA002P 값이 false가 되어도 호출
      pIsShowTSSSP203P: function (val) {
        var lv_vm = this
        // console.log('■■■■■■■■■■■■ >>>>> watch lv_vm.$props.pIsShowTSSSP203P = ' + lv_vm.$props.pIsShowTSSSP203P)
        // 부모창에서
        lv_vm.lv_isShowTSSSP203P = lv_vm.$props.pIsShowTSSSP203P
        // 인쇄/이메일/SMS 고객목록 팝업이 화면에 노출되는 시점에 고객그룹정보 조회
        if (lv_vm.lv_isShowTSSSP203P) {
          // 검색어 영역 초기화
          lv_vm.lv_searchKeyword = ''
          // 고객분류목록 '전체고객'으로 초기화
          lv_vm.lv_selectGrpInfo.all = lv_vm.lv_selectGrpInfo.items[0]
          this.fn_onInitCustList()
          this.fn_onSearchCustList()
        }
      },
      // table내 record가 갖는 checkbox 선택 시 후처리
      lv_selectedCustItems () {
        if ((this.lv_selectedCustItems.length === this.lv_custListItems.length) && this.lv_selectedCustItems.length > 0) {
          this.lv_isCustListSelectAll = true
        } else {
          this.lv_isCustListSelectAll = false
        }
        if (this.lv_selectedCustItems.length > 0) {
          this.lv_isBottomBarCheck = true
        } else {
          this.lv_isBottomBarCheck = false
        }
      },
      lv_searchKeyword () {
        let englishOrKorean = /[A-Za-z]/
        if (!this.$bizUtil.isEmpty(this.lv_searchKeyword)) {
          if (englishOrKorean.test(this.lv_searchKeyword)) {
            this.lv_searchKeyword = this.lv_searchKeyword.toUpperCase()
          }
        }
      }
    },


    /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
    created () {
      // 하드웨어 백키 사용을 위함.
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

      if(this.pIsSearch){
        this.lv_searchKeyword = '' // 검색어 영역 초기화
        this.lv_selectGrpInfo.all = this.lv_selectGrpInfo.items[0] // 고객분류목록 '전체고객'으로 초기화
        this.fn_onInitCustList()
        this.fn_onSearchCustList()
      }
      if (!this.$bizUtil.isEmpty(this.paramData)) {
         if (this.paramData[0].pPage === 'MSPCM212M') this.isMSPCM212M = true
      }
    },


    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      this.fn_onSearchBtnClicked()
    },

    beforeDestroy () {
      // 하드웨어 백키 사용을 위함.
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_HistoryBack
       * 설명       : 뒤로가기 버튼
       ******************************************************************************/
      fn_HistoryBack () {
        if (this.checkCount > 0) this.fn_confirm()
        else this.fn_onCancelBtnClicked()
      },

      fn_OnScroll (scrollLeft, scrollTop) {
        this.isScrolled = scrollTop > 0
      },

      /******************************************************************************
      * Function명: fn_init
      * 설명: 초기화 작업
      * Params: N/A
      * Return: none
      ******************************************************************************/
      fn_init () {
        // 사용자 정보
        this.lv_userInfo = this.getStore('userInfo').getters.getUserInfo
      },

      /******************************************************************************
      * Function명: fn_isCustListSelectAllItems
      * 설명: 체크박스 전체 선택
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      fn_isCustListSelectAllItems (v) {
        if (v) {
        // checked
          this.lv_selectedCustItems = this.lv_custListItems.slice(0)
          this.lv_custListItems.filter(item => item.chkClass = true)
        } else {
          // unchecked
          this.lv_selectedCustItems.splice(0, this.lv_selectedCustItems.length)
          this.lv_custListItems.filter(item => item.chkClass = false)
        }
      },

      /*********************************************************
       * Function명: fn_CheckBoxIsPossible
       * 설명: 체크박스에서 총 30개를 선택할때 더 이상 체크를 못하도록 한다.
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_checkBoxIsPossible (pParam, item, idx) {
        if (item.chkClass === true) {
          this.lv_custListItems[idx].chkClass = false
        } else {
          this.lv_custListItems[idx].chkClass = true
        }
        
        if (this.isMSPCM212M) {
          // 내그룹 추가 시, 30명 제한 없음
          return
        }

        // 최대 30명까지 선택하도록 제한한다.
        const LIMITED_SMS_SELECTED = 30

        if (pParam > LIMITED_SMS_SELECTED) {
          this.lv_selectedCustItems.splice(this.lv_selectedCustItems.length, pParam)
          if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local' ) console.log('이미 30명을 선택했습니다')

          window.fdpbridge.exec('ToastPlugin', {message: '이미 30명을 선택했습니다.\n한번에 최대 30명까지 선택가능합니다.'}, () => {}, () => {})
        }
        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local' ) console.log('====> pParm= ' + pParam)
      },

      /*********************************************************
       * Function명: fn_onInitCustList
       * 설명: 고객목록 초기화
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_onInitCustList () {
        let lv_vm = this
        lv_vm.lv_sACntntCustVOList = []
        lv_vm.lv_custListItems = []
        lv_vm.lv_custListTotalCnt = 0
        lv_vm.lv_custListMoreData = false
        // 데이터가 없는 상태이므로 false로 설정
        lv_vm.lv_isCustListLoadingStatus = true
        lv_vm.lv_selectedCustItems = []
      },

      /*********************************************************
       * Function명: fn_onSearchBtnClicked
       * 설명: 검색버튼 클릭 이벤트 핸들러
       * Params: N/A
       * Return: 고객그룹결과 목록 (List<SAGrpInfoSVO>)
       *********************************************************/
      fn_onSearchBtnClicked () {
        this.fn_onInitCustList()
        this.fn_onSearchCustList()
      },

      /******************************************************************************
       * Function명 : fn_LoadingData
       * 설명       : 고객목록 더보기
       ******************************************************************************/
      fn_LoadingData () {
        // post 호출 전 Progress 타입 설정
        window.vue.getStore('progress').dispatch('PART')

        this.fn_chkMoreCustList()
      },

      /*********************************************************
       * Function명: fn_onSearchCustList
       * 설명: 고객 검색
       * Params: N/A
       * Return: 고객 목록 (List<SAselListCntntCustSVO>)
       *********************************************************/
      fn_onSearchCustList: function () {

        var lv_vm = this
        // 서비스 호출 헤더데이터 전용
        lv_vm.isAfterFirstSearch = false
        // const trnstId = 'txTSSSA12S2'
        const trnstId = 'txTSSCM49S6'
        const _auth = 'S'
        let a = ''
        let pParams =
        {
          cnsltNo: lv_vm.getStore('userInfo').getters.getUserInfo.userId, // 컨설턴트번호
          fofOrgNo: lv_vm.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo, // 지점조직번호
          pesnTypVal: 'G', // 컨설턴트 콤보 조회된 인사유형값 (G,F,R 등) - 디폴트
          deltYn: 'N', // 삭제여부 - 디폴트
          custNm: lv_vm.lv_searchKeyword, // 고객명
          custScCd: lv_vm.lv_selectGrpInfo.selectdVal.value, // 고객구분코드 (전체)
          searchFlag: '' // 조회구분체크값 : 초성검색일때 'X'
        }

        lv_vm.pageRowCnt = lv_vm.lv_custListPageRowCnt
        lv_vm.stndKeyList = [{stndKeyId: 'nextKey', stndKeyVal: ''}]

        // 데이터 호출중인 관계로 목록 하단에 스크롤바가 도달하여도 재 호출방지를 위해 true로 설정
        lv_vm.lv_isCustListLoadingStatus = true

        this.post(lv_vm, pParams, trnstId, _auth)
          .then(function (response) {
            lv_vm.isAfterFirstSearch = true
            // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            if (lv_vm.$commonUtil.isSuccess(response)) {
              // 서비스 후처리
              if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                // 서비스 통신 조회키
                lv_vm.stndKeyList[0] = response.trnstComm.stndKeyList[0]
                
                lv_vm.lv_sACntntCustVOList = response.body.iCCntntCustVO

                // 검색 후, 전체 데이터를 다시 보여주기 위해 저장
                if (response.body.iCCntntCustVO != null && response.body.iCCntntCustVO.length > 0) {
                  lv_vm.lv_custListItems = lv_vm.lv_sACntntCustVOList.slice()

                  for (let i = 0; i < lv_vm.lv_custListItems.length; i++) {
                    lv_vm.lv_custListItems[i].chkClass = false
                  }

                  // // 검색 결과 목록 총 건수
                  lv_vm.lv_custListTotalCnt = lv_vm.lv_sACntntCustVOList.length

                  // 더보기 설정
                  if (lv_vm.stndKeyList !== null && lv_vm.stndKeyList[0] !== null && lv_vm.stndKeyList[0].stndKeyVal !== null && lv_vm.stndKeyList[0].stndKeyVal.trim() !== '') {
                  // 다음페이지 데이터 존재함
                    lv_vm.lv_custListMoreData = true
                  } else {
                  // 다음페이지 데이터 존재안함(마지막 페이지)
                    lv_vm.lv_custListMoreData = false
                  }
                  // 내그룹-고객추가(MSPCM212M)에서 진입 시 전체 고객목록에서 보유고객 제외
                  if (lv_vm.isMSPCM212M) {
                    let newList = []
                    for (let j in lv_vm.lv_custListItems) {
                      if (!lv_vm.$bizUtil.isEmpty(lv_vm.paramData[0].pMember.find(e => e.chnlCustId === lv_vm.lv_custListItems[j].chnlCustId))) {
                        j++
                      }
                      else newList.push(lv_vm.lv_custListItems[j])
                    }
                    lv_vm.lv_custListItems = newList
                    lv_vm.lv_custListTotalCnt = lv_vm.lv_custListItems.length
                    // 필터된 리스트가 한 페이지 분량보다 짧은 경우, 셀프 페이징
                    if (lv_vm.lv_custListTotalCnt < lv_vm.lv_custListPageRowCnt) {
                      lv_vm.fn_LoadingData()
                    }
                  }
                } else {
                  lv_vm.lv_custListSearchStatus = '2'
                }

                // 데이터가 응답(완료)되었으므로 목록 하단에 스크롤바가 도달하였을경우 데이터를 더 읽어올 수 있도록 상태를 false로 설정
                lv_vm.lv_isCustListLoadingStatus = false
                // 조회후 이전 조회 결과에서 선택된 고객 목록 초기화
                lv_vm.lv_selectedCustItems = []
                
              } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
                lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
                lv_vm.lv_custListSearchStatus = '2'
              }
            } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
              lv_vm.lv_custListSearchStatus = '2'
            }
          })
          .catch(function (error) {
            window.vue.error(error)
            lv_vm.lv_custListSearchStatus = '2'
          })
      },
      
      /*********************************************************
       * Function명: fn_chkMoreCustList
       * 설명: (infinite 사용시) 서비스 결과 데이터를 로딩한다.
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_chkMoreCustList () {
        // list 로딩 시, 데이터 조회하는 부분을 여기에 추가
        this.lv_isCustListLoadingStatus = true
        // infinite 추가 로딩인지
        if (this.lv_custListMoreData) {
          // 조회할 데이터가 더 있는지 확인
          // 데이터 조회 서비스 호출
          this.fn_onMoreSearchCustList()
        } else {
          this.lv_isCustListLoadingStatus = true // infinite 추가 로딩인지
          // this.getStore('toast').dispatch('ADD', '더이상 조회할 데이터가 없습니다.')
          // 스마트폰은 안드로이드 네이티브 toast 를 사용한다.
          window.fdpbridge.exec('ToastPlugin', {message: '더이상 조회할 데이터가 없습니다.'}, () => {}, () => {})
          
        }
      },

      /*********************************************************
       * Function명: fn_onMoreSearchCustList
       * 설명: 입력된 사용자 키워드에 의해 고객정보를 추가 조회, 검색한다.
       * Params: cnsltNo: 컨설턴트 번호 (String) <필수>
       *         custNm: 고객명[검색키워드] (String) <필수>
       *
       * Return: 고객검색결과 목록 (List<CMCustInfoSVO>)
       *********************************************************/
      fn_onMoreSearchCustList () {
        // (실행시) Vue객체모델 인스턴스
        var lv_vm = this

        // 서비스 호출 헤더데이터 전용
        // const _trnstId = 'txTSSSA12S2'
        const _trnstId = 'txTSSCM49S6'
        const _auth = 'S'

        let pParams =
        {
          // cnsltNo: '0003250761',
          cnsltNo: lv_vm.getStore('userInfo').getters.getUserInfo.userId, // 컨설턴트번호
          fofOrgNo: lv_vm.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo, // 지점조직번호
          pesnTypVal: 'G', // 컨설턴트 콤보 조회된 인사유형값 (G,F,R 등) - 디폴트
          deltYn: 'N', // 삭제여부 - 디폴트
          custNm: lv_vm.lv_searchKeyword, // 고객명
          custScCd: lv_vm.lv_selectGrpInfo.selectdVal.value, // 고객구분코드
          searchFlag: '' // 조회구분체크값 : 초성검색일때 'X'
          // chnlCstgrId: lv_vm.lv_selectGrpInfo.value.key // 채널고객그룹ID
        }
        lv_vm.pageRowCnt = lv_vm.lv_custListPageRowCnt

        this.post(lv_vm, pParams, _trnstId, _auth)
          .then(function (response) {
            if ( lv_vm.$commonUtil.isSuccess(response) ) {
              // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
              // 서비스 후처리
              if (response.body !== null && response.body !== '') {
                // 서비스 호출 결과(body)가 있을 경우
                // 서비스 통신 조회키
                lv_vm.stndKeyList[0] = response.trnstComm.stndKeyList[0]
                // 서버 데이터 가져오기
                if (response.body.iCCntntCustVO !== null && response.body.iCCntntCustVO.length > 0) {
                  lv_vm.lv_sACntntCustVOList = lv_vm.lv_sACntntCustVOList.concat(response.body.iCCntntCustVO)
                  // 검색 후, 전체 데이터를 다시 보여주기 위해 저장
                  lv_vm.lv_custListItems = lv_vm.lv_sACntntCustVOList.slice()

                  for (let i = 0; i < lv_vm.lv_custListItems.length; i++) {
                    if (lv_vm.lv_custListItems[i].chkClass === true){
                      // 이미 선택이 되어있는 경우 스킵
                    } else {
                      lv_vm.lv_custListItems[i].chkClass = false
                    }
                  }

                  // 검색 결과 목록 총 건수
                  lv_vm.lv_custListTotalCnt = lv_vm.lv_sACntntCustVOList.length

                  // 더보기 설정
                  if (lv_vm.stndKeyList !== null && lv_vm.stndKeyList[0] !== null && lv_vm.stndKeyList[0].stndKeyVal !== null && lv_vm.stndKeyList[0].stndKeyVal.trim() !== '') {
                    // 다음페이지 데이터 존재함
                    lv_vm.lv_custListMoreData = true
                  } else {
                    // 다음페이지 데이터 존재안함(마지막 페이지)
                    lv_vm.lv_custListMoreData = false
                  }
                  // 데이터가 응답(완료)되었으므로 false
                  lv_vm.lv_isCustListLoadingStatus = false
                  // 내그룹-고객추가(MSPCM212M)에서 진입 시 전체 고객목록에서 보유고객 제외
                  if (lv_vm.isMSPCM212M) {
                    let newList = []
                    for (let j in lv_vm.lv_custListItems) {
                      if (!lv_vm.$bizUtil.isEmpty(lv_vm.paramData[0].pMember.find(e => e.chnlCustId === lv_vm.lv_custListItems[j].chnlCustId))) {
                        j++
                      }
                      else newList.push(lv_vm.lv_custListItems[j])
                    }
                    lv_vm.lv_custListItems = newList
                    lv_vm.lv_custListTotalCnt = lv_vm.lv_custListItems.length
                    // 필터된 리스트가 한 페이지 분량보다 짧은 경우, 셀프 페이징
                    if (lv_vm.lv_custListTotalCnt < lv_vm.lv_custListPageRowCnt) {
                      lv_vm.fn_LoadingData()
                    }
                  }
                } else {
                }
              } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
                lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
              }
            } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
                lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
            // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          })
      },

      /*********************************************************
       * Function명: fn_onCancelBtnClicked
       * 설명: 취소버튼 클릭 이벤트 핸들러
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_onCancelBtnClicked () {
        var lv_vm = this
        // 고객목록 초기화
        //lv_vm.fn_onInitCustList()
        // 종료 이벤트 발생
        lv_vm.$emit('popupCloseMSPSA203P', '')
      },
      
      
      /*********************************************************
       * Function명: fn_onNextBtnClicked
       * 설명: 다음버튼 클릭 이벤트 핸들러
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_onNextBtnClicked () {
        if (this.isMSPCM212M) {
          // 내그룹 추가 시, 30명 제한 없음
          // 종료 이벤트 발생
          this.$emit('popupCloseMSPSA203P', this.lv_selectedCustItems)
          return
        }
        
        // 최대 30명까지 선택하도록 제한한다.
        const LIMITED_SMS_SELECTED = 30
        var lv_vm = this
        if (this.lv_selectedCustItems.length > LIMITED_SMS_SELECTED) {
          window.fdpbridge.exec('ToastPlugin', {message: '최대 30명까지만 문자발송 가능.\n총선택 인원을 확인해주세요.'}, () => {}, () => {})
        } else if (this.lv_selectedCustItems.length === 0) {
          //요건 안나올거가틈
          //window.fdpbridge.exec('ToastPlugin', {message: '보낼 대상을 선택해 주세요.'}, () => {}, () => {})
        } else {
          // 종료 이벤트 발생
          lv_vm.$emit('popupCloseMSPSA203P', this.lv_selectedCustItems)
        }
      },

      /******************************************************************************
       * @description confirm popup
       * @param {String} title 제목
       * @param {String} message confirm 내용
       ******************************************************************************/
      fn_confirm(title, message) {
        let lv_Vm = this
        let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
        let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              type: 'B',
              content: backMsg,
              title_pos_btn: "예",
              title_neg_btn: "아니오"
            },
            listeners: {
              onPopupConfirm: () => {
                lv_Vm.$bottomModal.close(lv_AlertPop)
                this.fn_onCancelBtnClicked()
              },
              onPopupClose: () => {
                lv_Vm.$bottomModal.close(lv_AlertPop)
              }
            }
          })
      },

    }
  };
</script>
<style scoped>
</style>